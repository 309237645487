import React, { Component } from "react";
import Quote from "../components/QuoteComp";
import Button from "react-bootstrap/Button";
import Banner from "../images/banner.png";

const MainBannerComp = () => {
  return (
    <div className="hp__banner">
      <img src={Banner} alt="banner" />
 
      <div className="banner__text"> 
        <h1>
          Get a <span>Rental car</span> at a very cheap price
        </h1>
        <p>
          There are many choices of very cool cars from various famous brands in
          the world, you can rent this cool car at a very affordable price for
          all people.
        </p>
      </div> 
      <Quote />
    </div>
  );
};

export default MainBannerComp;
