import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Col, Container, Row } from "react-bootstrap";
import review from "../images/reviweer.png";

const ReviewSlider = ({ Info = null, SectionTitle }) => {
  const defaultInfo = [
    {
      icon: review,
      title: "Hannah Schmitt",
      date: "May 8 2020",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus ",
    },
    {
      icon: review,
      title: "Mr. Man",
      date: "May 8 2022",
      description:
        "Labore et dolore magna aliqua. Ut enim corehenderit in cupidatat non proident, sun.",
    },
    {
      icon: review,
      title: "John Doe",
      date: "July 8 2022",
      description:
        "Serspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
    },
  ];

  if (!Info) Info = defaultInfo;
  return (
    <>
      <div className="section__gap homepage-review  pb-5">
        <Container fluid>
          
          <Row>
            <Col xs lg="6">
            <h2 className="section__title text-left">
            What Customer Say <br/>
            About <span>  Us </span> 
          </h2>
          <p className="text-left">
          What do our customers feel when they are with Carrent, check this next, guys
          </p>
            </Col>
            <Col xs lg="6">
              <Carousel autoPlay={true}>
                {Info.map((info, i) => (
                  <div className="reviewitem" key={i}>
                    <div className="reviewer-info">
                      <img src={info.icon} alt="" />
                      <h5>
                        {info.title}
                        <small>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        </small>
                      </h5>
                    </div>
                    <p className="text-left">{info.description}</p>
                  </div>
                ))}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default ReviewSlider;
