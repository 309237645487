import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import SmLogo from "../images/qus.png";
import callIcon from "../images/call-icon.png";

const ThreeColWithLogoDark = () => {
  return (
    <>
      <div className="section__gap">
        <Container fluid>
          <div className="homepage-more-about">
            <h4>
              Have any question <br />
              about us?
            </h4>
            <p>
              <a href="tel:9783712224" className="btn-white"><img src={callIcon} /> 978-371-2224</a>
              <a href="#" className="btn-ghost"> Contact Us</a>
            </p>
            <img src={SmLogo} className="float" alt="" />
          </div>
        </Container>
      </div>
    </>
  );
};
export default ThreeColWithLogoDark;
