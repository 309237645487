import React, { useEffect, useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Col, Row } from "react-bootstrap";
import Quote from "../components/QuoteComp";
import Button from "react-bootstrap/Button";
import FromAirport from "../images/airprot-plane-icon.png";
import ToAirport from "../images/to-airprot-icon.png";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const QuoteComp = () => {
  const mapApiKey = 'AIzaSyCXXZ9VqXu2DV6UfyOLKERn074at4mY8BI';

  const [start, setStart] = useState({
    lat: "",
    lng: "",
    name: "",
  });

  const [end, setEnd] = useState({
    lat: "",
    lng: "",
    name: "",
  });

  const pax = [
    { value: "1", label: "Passenger", isSelected: true, isDisabled: true },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  const lug = [
    { value: "0", label: "Luggage", isSelected: true, isDisabled: true },
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  const infant = [
    { value: "0", label: "Infant seat (0-15 Mon)", isDisabled: true },
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
  ];

  const regular = [
    { value: "0", label: "Regular Seat (15-36 Mon)", isDisabled: true },
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
  ];

  const booster = [
    { value: "0", label: "Booster Seat (Above 3 yrs)", isDisabled: true },
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
  ];


  useEffect(() => {
    setStart({
      lat: 42.3656132,
      lng: -71.0095602,
      name: "Boston Logan International Airport",
    });
  }, [setStart]);

  // Errors
  const [startError, setStartError] = useState("");
  const [endError, setEndError] = useState("");
  const [dateError, setDateError] = useState("");

  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState("from_airport");
  const [showHideChildSeat, setShowHideChildSeat] = useState(false);

  const radios = [
    {
      name: "From Airport",
      value: "from_airport",
      img: FromAirport,
    },
    {
      name: "To Airport",
      value: "to_airport",
      img: ToAirport,
    },
  ];

  // Date & TIme
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const handleChildSeat = (event) => {
    setShowHideChildSeat(event.target.checked)
  };

  const handleFromTo = (e) => {
    if (e === "to_airport") {
      setRadioValue("to_airport");
      setEnd({
        lat: 42.3656132,
        lng: -71.0095602,
        name: "Boston Logan International Airport",
      });
      setStart({ lat: "", lng: "", name: "" });
    } else {
      setRadioValue("from_airport");
      setStart({
        lat: 42.3656132,
        lng: -71.0095602,
        name: "Boston Logan International Airport",
      });
      setEnd({ lat: "", lng: "", name: "" });
    }
    setEndError("");
    setStartError("");
  };

  // Form Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (start.name && end.name) {
      const data = {
        service_type: radioValue,
        from: start.name,
        to: end.name,
        start_lat: start.lat,
        start_lng: start.lng,
        end_lat: end.lat,
        end_lng: end.lng,
        pick_date: e.target[4].value,
        pick_time: e.target[5].value,
        passengers: e.target[6].value,
        luggage: e.target[7].value,
        infant_seat: e.target[9] ? e.target[9].value : "0",
        regular_seat: e.target[10] ? e.target[10].value : "0",
        booster_seat: e.target[11] ? e.target[11].value : "0",
      };
      window.location.href = `https://booking.airporttaxima.com/quote?service_type=${data.service_type}&from=${data.from}&start_lat=${data.start_lat}&start_lng=${data.start_lng}&to=${data.to}&end_lat=${data.end_lat}&end_lng=${data.end_lng}&pick_date=${data.pick_date}&pick_time=${data.pick_time}&passengers=${data.passengers}&luggage=${data.luggage}&infant_seat=${data.infant_seat}&regular_seat=${data.regular_seat}&booster_seat=${data.booster_seat}`;
    } else {
      if (!start.name) {
        setStartError("Please fillup Pickup Address");
      }

      if (!end.name) {
        setEndError("Please fillup Drop off Address");
      }
    }
  };

  return (
    <>
      <div className="quote__wrapper">
        <Form onSubmit={handleSubmit}>
          <ButtonGroup className="mb-2" style={{textAlign: "center"}}>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant="secondary"
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => handleFromTo(e.currentTarget.value)}
              >
                <img src={radio.img} alt="img" style={{height: "auto", width: "auto"}} /> {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>

          <Form.Group className="mb-4 start-address">
            {radioValue == "from_airport" ? (
              <Form.Control type="text" placeholder="Pick up Address" value="Boston Logan International Airport (BOS), Boston, MA, USA" readOnly />
            ) : (<ReactGoogleAutocomplete
              apiKey={mapApiKey}
              placeholder="Pickup Address"
              className="form-control"
              onPlaceSelected={(place) => {
                setStart({
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                  name: place.formatted_address,
                });
              }}
              options={{
                types: ["geocode", "establishment"],
                componentRestrictions: { country: ["us"] },
              }}
              onClick={() => setStartError("")}
            />
            )}
            <p className="text-danger">{startError}</p>
          </Form.Group>

          <Form.Group className="mb-3 link-address end-address">
            {radioValue == "to_airport" ? (
              <Form.Control type="text" placeholder="Drop Off Address" value="Boston Logan International Airport (BOS), Boston, MA, USA" readOnly />
            ) : (<ReactGoogleAutocomplete
              apiKey={mapApiKey}
              placeholder="Drop Off Address"
              className="form-control"
              onPlaceSelected={(place) => {
                setEnd({
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                  name: place.formatted_address,
                });
              }}
              options={{
                types: ["geocode", "establishment"],
                componentRestrictions: { country: ["us"] },
              }}
              onClick={() => setEndError("")}
            />
            )}
            <p className="text-danger">{endError}</p>
          </Form.Group>

          <Row className="mb-3">
            <Col lg="6" md="6" sm="6" className="mb-3">
              <ReactDatePicker
                selected={startDate}
                className='form-control'
                onChange={(date) => setStartDate(date)}
                minDate={new Date()}
                placeholderText="Pickup Date"
              />
              {/* <InputGroup className="mb-3">
                <Form.Control placeholder="Date" />
                <InputGroup.Text>
                  <i class="fa-regular fa-calendar"></i>
                </InputGroup.Text>
              </InputGroup> */}
            </Col>
            <Col lg="6" md="6" sm="6">
              <ReactDatePicker
                selected={startTime}
                onChange={(date) => setStartTime(date)}
                showTime={{ use12Hours: true, format: "HH:mm a" }}
                showTimeSelectOnly
                showTimeSelect
                dateFormat="hh:mm a"
                placeholderText="Pickup Time"
                timeIntervals={15}
                type="text"
                className="form-control"
              />
              {/* <InputGroup className="mb-3">
                <Form.Control placeholder="Time" />
                <InputGroup.Text>
                  <i class="fa-regular fa-clock"></i>
                </InputGroup.Text>
              </InputGroup> */}
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6" sm="6">
              <InputGroup className="mb-3">
                {/* <Form.Control placeholder="Passenger" /> */}
                <Form.Select className="form-control">
                  {pax.map(p => (
                    <option value={p.value}>{p.label}</option>
                  ))}
                </Form.Select>
                <InputGroup.Text>
                  <i class="fa-regular fa-user"></i>
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col lg="6" md="6" sm="6">
              <InputGroup className="mb-3">
                {/* <Form.Control placeholder="Luggage" /> */}
                <Form.Select className="form-control">
                  {lug.map(l => (
                    <option value={l.value}>{l.label}</option>
                  ))}
                </Form.Select>
                <InputGroup.Text>
                  <i class="fa-solid fa-suitcase-rolling"></i>
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Check type="checkbox" onChange={(e) => handleChildSeat(e)} label="Add Child & Infants" />
          </Form.Group>
          <Row style={{ display: showHideChildSeat ? '' : 'none' }}>
            <Col lg="4" md="4" sm="6">
              <Form.Group className="mb-3" >
                {/* <Form.Control placeholder="Infant (0-15 mon)" /> */}
                <Form.Select className="form-control">
                  {infant.map(i => (
                    <option value={i.value}>{i.label}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg="4" md="4" sm="6">
              <Form.Group className="mb-3" >
                <Form.Select className="form-control">
                  {regular.map(i => (
                    <option value={i.value}>{i.label}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg="4" md="4" sm="6">
              <Form.Group className="mb-3" >
                <Form.Select className="form-control">
                  {booster.map(i => (
                    <option value={i.value}>{i.label}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          {/* done changes over here for responsive button */}
          <Button type="submit" className="btn-block btn__main" style={{width: "100%"}}>
            {" "}
            <i class="fa-regular fa-clock"></i> Choose Vehicle
          </Button>
        </Form>
      </div>
    </>
  );
};
export default QuoteComp;
