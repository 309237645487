import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/boston-airport.png";
import FooterComp from "../components/FooterComp";

const Fleet = () => {
  return (
    <>
      <Nav
        SeoTitle="Logan Airport Pick Up Service at Airport Taxi Service MA"
        SeoDesc="Logan Airport Pick Up Service - Serving Boston Airport, Logan Airport to MA taxi service, Boston shuttle service, Door to doors transfer boston. We are providing the best airport car transportation service, our mission is to offer efficient and reliable airport transportation service in the Boston. Call us (617) 405-3000."
      />
      <InnerBanner BannerImage={Banner} />
      <section className="section__gap">
        <Container>
          <h1 className="text-center mb-5">
            LOGAN AIRPORT TRANSPORTATION - PICKUP LOCATION
          </h1>
          <h5>WHERE TO MEET YOUR DRIVER AT LOGAN AIRPORT</h5>
          <p>
            <strong>Logan Airport Transportation:</strong> Meet your Boston
            Airport Taxi Service driver in Limo/taxi stand at each terminal.
          </p>
          <h4>Terminal A-DELTA/ALASKA</h4>
          <p>
            Go outside the baggage claim, cross over two traffic lanes and walk
            to the left to Meet your driver at the designated limo stand.
          </p>
          <h5> Terminal B - USAIR/AIR CANADA/SPIRIT</h5>
          <p>
            Go ground floor parking garage , go outside the door and walk to
            parking lot limo pickup arrival . Driver will be waiting for you at
            the designated limo stand.
          </p>
          <h5>Terminal C- JETBLUE</h5>
          <p>
            Please proceed to baggage claim. Take the escalator to the top
            level. Go outside the door beyond the drop off area. Driver will be
            waiting with sign at the limo stand.
          </p>

          <h5>Terminal E ALL</h5>
          <p>
            Proceed to the terminal exit. Go outside the door, cross over two
            traffic lanes and walk to the left. Driver will be waiting for you
            at the limo stand.
          </p>

          <h5>Boston South Station (All Trains)</h5>
          <p>
            Exit South station terminal to Atlantic Ave. Walk to the left
            towards taxi stand
          </p>
        </Container>
      </section>
      <FooterComp />
    </>
  );
};

export default Fleet;
