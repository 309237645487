import React from "react";
import {Helmet} from "react-helmet";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useLocation } from "react-router-dom";
import logo from "../images/logo.png";

const HeaderComp = ({SeoTitle,SeoDesc}) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const openNav = () => {
    document.getElementById("sidenav").style.left = "0px";
  };
  const closeThisNav = () => {
    document.getElementById("sidenav").style.left = "-250px";
  };
  return (
    <>
      <Helmet> 
        <title>{SeoTitle}</title> 
        <meta name="description" content={SeoDesc} />
        
      </Helmet>
      <Navbar className="Nav__wrapper" collapseOnSelect expand="lg" onClick={openNav}>
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="m-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link
                href="/"
                className={splitLocation[1] === "" ? "active" : ""}
              >
                Home
              </Nav.Link>

              <Nav.Link
                href="/boston-airport-minivan"
                className={
                  splitLocation[1] === "boston-airport-minivan" ? "active" : ""
                }
              >
                Boston Airport Minivan
              </Nav.Link>
              <Nav.Link
                href="/pickup-location"
                className={splitLocation[1] === "pickup-location" ? "active" : ""}
              >
                Pickup Location
              </Nav.Link>
              <Nav.Link
                href="/fleets"
                className={splitLocation[1] === "fleets" ? "active" : ""}
              >
                Fleet
              </Nav.Link>
              <Nav.Link
                href="/contact"
                className={splitLocation[1] === "contact" ? "active" : ""}
              >
                Contact
              </Nav.Link>
              <Nav.Link href="#" className="btn__main">
                Login
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default HeaderComp;
