import React from "react";

import Nav from "../components/NavComp";
import Banner from "../components/MainBannerComp";
import ThreeColWithImage from "../components/ThreeColWithImage";
import TwoColAppSection from "../components/TwoColAppSection";
import ReviewSlider from "../components/ReviewSlider";
import FooterComp from "../components/FooterComp";
import ThreeColWithLogoDark from "../components/ThreeColWithLogoDarkBg";
import ThreeColWithImageHoverFlip from "../components/ThreeColWithImageHoverFlip";

const Index = ({SeoTitle,SeoDesc}) => {
  return (
    <>
      <Nav
        SeoTitle="Boston Logan Airport Minivan Cab and Taxi Service at Airport Taxi Service MA"
        SeoDesc="Boston airport minivan service have been providing airport cab and taxi service for more years now and our specialized airport transfers have earned us much fame and honor. We offer affordable and reliable Logan airport taxi cab, minivan, town car and airport transportation services to Boston Logan Airport safely."
      />
      <Banner /> 
      <ThreeColWithImageHoverFlip
        SectionTitle="Services we provide"
        SectionSmTitle="Service & Advantage"
      />
      <ThreeColWithImage
        SectionTitle="Trending  "
        SectionSmTitle="Fleets great & fast"
        BlueTitle="this week"
      />
      {/* <TwoColAppSection /> */}
      <ThreeColWithLogoDark />
      <ReviewSlider SectionTitle="What Our Clients are saying About us" />
      <FooterComp />
    </>
  );
};

export default Index;
