import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/boston-airport.png";
import FooterComp from "../components/FooterComp";

const About = () => {
  return (
    <>
      <Nav SeoTitle="" SeoDesc="" />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap area__page">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h2 className=" text-center centered section__title hav__icon mb-5">
                About Us
              </h2>
              <p>
                When you are eying to have a comfortable and safe journey
                returning from the airport or going to any place then it is best
                for you to hire a private cab service. Private car rental
                services are surging on the market due to their amazing fleet of
                luxury sedan and SUV cars and also for other benefits. Thus if
                you are also want to enjoy the pleasant and most efficient car
                rental service in Massachusetts then get in touch with The
                Airport Taxi MA is a must. We are one of the premier brands in
                this industry and served both commercial and private clients
                alike. Whatever may be the reason starting from your anniversary
                or birthday celebration to visiting various tourist attractions
                of Boston and other vicinities you can avail our services
                without any hassle. Moreover, our executive airport car hire
                service can give you a top of the shelf experience due to our
                top luxury vehicles and most efficient chauffeurs. The best part
                is that our service is not only confined to only city limits but
                we offer our services for areas which include Arlington,
                Cambridge, Belmont, Bedford, Lexington, Waltham, Stoneham,
                Concord, Woburn, Lincoln, Winchester, and Somerville, etc. We
                don’t charge any hidden fees and our price is fixed. Not only
                that even if you cancel your trip then also there will be a full
                refund of your payment. You can call us at our customer service
                for booking your car at any point in time to explore a whole new
                world of car hiring and enjoy lots of benefits too.
              </p>
              <p>
                Airport Taxi Service MA offers most efficient Boston MA taxi and
                car service for Logan airport transportation. Call (617)
                405-3000 today for bookings a Boston airport taxi, Logan airport
                transfer, Boston airport cab and Logan airport cab.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <FooterComp />
    </>
  );
};

export default About;
