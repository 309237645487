import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import InterCity from "../images/inter-city-ride.png";
import Hailing from "../images/chauffeur-hailing.png";
import Airport from "../images/airport-transfer.png";

const ThreeColWithImageHoverFlip = ({
  Data = null,
  SectionTitle,
  SectionSmTitle,
}) => {
  const defaultData = [
    {
      img: InterCity,
      title: "Intercity rides",
      description:
        "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
      num: "01",
      url: {},
    },
    {
      img: Hailing,
      title: "chauffeur hailing",
      description:
        "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
      num: "02",
      url: {},
    },
    {
      img: Airport ,
      title: "Airport transfers ",
      description:
        "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
      num: "03",
      url: {},
    },
  ];

  if (!Data) Data = defaultData;
  return (
    <>
      <div className="section__gap threecolwithimage__wrapper">
        <Container fluid>
          <div className="text-center">
            <h4 className="small__title">{SectionSmTitle}</h4>
            <h2 className="section__title text-center mb-60">{SectionTitle}</h2>

            <Row className="w-80">
              {Data.map((info, i) => (
                <Col
                  className="threecolwithimagehoverflip__unit"
                  lg="4"
                  key={i}
                >
                  <div className="hoverable_content">
                  <img src={info.img} alt={info.title || ""} />
                  <div className="overlay">
                    <h2>{info.num || ""}</h2>
                    <h4>{info.title || ""}</h4>
                    <p className="description">{info.description || ""}</p>
                  </div>
                  <h5 className="counter">{info.num || ""}</h5>
                  </div>
                  <h4>{info.title || ""}</h4>
                 
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ThreeColWithImageHoverFlip;
