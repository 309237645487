import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Img1 from "../images/suv.png";
import Img2 from "../images/sedan.png";
import Img3 from "../images/compact.png";

const ThreeColWithImage = ({
  Data = null,
  SectionTitle,
  SectionSmTitle,
  BlueTitle,
}) => {
  const defaultData = [
    {
      title: "COMPACT SEDAN",
      description: "Compact Sedan for up-to 2-Passenger",
      img: Img1,
      rating: "5.0",
      price: "$250",
      url: {},
    },
    {
      title: "3-PAX SEDAN",
      description: "Compact Sedan for up-to 2-Passenger",
      img: Img2,
      rating: "5.0",
      price: "$460",
      url: {},
    },
    {
      title: "6/7-PAX MINIVAN",
      description: "Compact Sedan for up-to 2-Passenger",
      img: Img3,
      rating: "4.5",
      price: "$670",
      url: {},
    },
  ];

  if (!Data) Data = defaultData;
  return (
    <div className="threecolwithimage__wrapper">
      <Container fluid>
        {/* Start of fleet heading */}
        <Row>
          {/* done changes here for responsive */}
          <Col lg="6" md="4" sm="4" xs="12">
            <div className="text-left">
              <h2 className="section__title">
                <span>{SectionTitle}</span>
                {SectionSmTitle}
                <span> {BlueTitle}</span>
              </h2>
            </div>
          </Col>
        </Row>
        <div className="text-center">
          <Tabs defaultActiveKey="sedan">
            <Tab eventKey="sedan" title="Sedan">
              <Row>
                {Data.map((info, i) => (
                  <Col className="threecolwithimage__unit" xs lg="4" key={i}>
                    <img src={info.img} alt={info.title || ""} />
                    <Row>
                      <Col lg="8">
                        <h4>{info.title || ""}</h4>
                        <p className="description">{info.description || ""}</p>
                      </Col>
                      <Col lg="4">
                        <div className="price-box">
                          <h5>{info.price}</h5>
                          <Button className="btn__main">Book Now</Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Tab>
            <Tab eventKey="van" title="Van">
              <Row>
                {Data.map((info, i) => (
                  <Col className="threecolwithimage__unit" xs lg="4" key={i}>
                    <img src={info.img} alt={info.title || ""} />
                    <Row>
                      <Col lg="8">
                        <h4>{info.title || ""}</h4>
                        <p className="description">{info.description || ""}</p>
                      </Col>
                      <Col lg="4">
                        <div className="price-box">
                          <h5>{info.price}</h5>
                          <Button className="btn__main">Book Now</Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Tab>
            <Tab eventKey="minivan" title="Minivan">
              <Row>
                {Data.map((info, i) => (
                  <Col className="threecolwithimage__unit" xs lg="4" key={i}>
                    <img src={info.img} alt={info.title || ""} />
                    <Row>
                      <Col lg="8">
                        <h4>{info.title || ""}</h4>
                        <p className="description">{info.description || ""}</p>
                      </Col>
                      <Col lg="4">
                        <div className="price-box">
                          <h5>{info.price}</h5>
                          <Button className="btn__main">Book Now</Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Tab>
          </Tabs>
        </div>
      </Container>
    </div>
  );
};

export default ThreeColWithImage;
