import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import logo from "../images/logo.png";

const FooterComp = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <footer>
        <Container fluid>
          <div className="suscribe-part">
            <h3 className="section__title text-center text-white">
              Traveling to Gatwick airport <br />
              from your <span>Location ?</span>
            </h3>
            <p style={{ textAlign: "start" }}>
              If you need a taxi for Heathrow airport from towns like Sevenoaks,
              Dorking, Godstone, Reigate, Redhill just give us a call. To reach
              Gatwick from areas like West Malling, Tonbridge contact us for a
              hassle free luxury journey. We also have our taxi service and
              Getwick minibus hire in Maidstone, Canterbury and Ashford.
            </p>
          </div>
          <Row>
            <Col lg="2">
              <img src={logo} />
              <p className="text-left">
                Book a Gatwick taxi and save time, effort and resources!!
              </p>
            </Col>
            <Col lg="2" style={{ paddingTop: "5%" }}>
              <h4>Links</h4>
              <ListGroup>
                <ListGroup.Item>
                  <a href="/about">About</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/area">Area We Serve</a>
                </ListGroup.Item>

                <ListGroup.Item>
                  <a href="/faq">FAQ</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/terms">Terms & Conditions</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/privacy">Privacy Policy</a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col lg="5" style={{ paddingTop: "5%" }}>
              <h4>AREA WE SERVE</h4>
              <ListGroup className="two_col_list">
                <ListGroup.Item>
                  <a href="/belmont-ma-taxi-service">Belmont MA Taxi Service</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/airport-taxi-service-ma">
                    Airport Taxi Service MA{" "}
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/boston-airport-taxi-service-ma">
                    Boston Airport Taxi Service MA
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/ma-to-logan-airport-taxi-service">
                    MA To Logan Airport Taxi Service
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/airport-taxi-boston">Airport Taxi Boston</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/boston-taxi-service">Boston Taxi Service</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/airport-taxi-cambridge-ma">
                    {" "}
                    Airport Taxi Cambridge MA
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/airport-taxi-service-boston">
                    {" "}
                    Airport Taxi Service Boston
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/taxi-service-boston-ma">Taxi Service Boston MA </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/concord-ma-taxi-services">
                    {" "}
                    Concord MA Taxi Services{" "}
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/boston-taxi-services">Boston Taxi Services </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/winchester-ma-taxi-service">
                    Winchester MA Taxi Service{" "}
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/taxi-service-medford-ma">
                    Taxi Service Medford MA{" "}
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/boston-airport-minivan-service">
                    Boston Airport Minivan Service{" "}
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/waltham-ma-taxi-service">
                    Waltham MA Taxi Service{" "}
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/lexington-airport-car-service">
                    Lexington Airport Car Service{" "}
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col lg="3" style={{ paddingTop: "5%" }}>
              <h4>Get in touch</h4>
              <p className="text-left">
                <strong>Office: </strong>
                Boston - Cambridge, MA 02139
              </p>
              <p className="text-left">
                <strong>Phone: </strong>
                <a href="tel:6174053000">(617) 405-3000</a>
              </p>
              <p className="text-left">
                <strong>Email: </strong>
                <a href="mailto:info@airporttaxima.com">
                  info@airporttaxima.com
                </a>
              </p>
              <div className="social-icons">
                <a href="#!">
                  <i class="fa-brands fa-facebook-f"></i>
                </a>
                <a href="#!">
                  <i class="fa-brands fa-twitter"></i>
                </a>
                <a href="#!">
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </div>
            </Col>
          </Row>
          <div className="copyright text-center">
            <p>Copyright {year} Airport Taxi MA. All rights reserved.</p>
          </div>
        </Container>
      </footer>
    </>
  );
};
export default FooterComp;
