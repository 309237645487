import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/area.png";
import FooterComp from "../components/FooterComp";

const Location = (props) => {
  return (
    <>
      <Nav SeoTitle={props.SeoTitle} SeoDesc={props.SeoDesc} />
      <InnerBanner BannerImage={Banner} /> 
      <div className="section__gap faq__page">
        <Container fluid>
          <h1 className="text-center mb-5">{props.title}</h1>
          <Row>
            <Col lg="12"> 
            <h4 className="mt-4 mb-4 text-color">{props.title1}</h4>        
                {props.para1 && <p> {props.para1} </p> }
                {props.para2 && <p> {props.para2} </p> }
                {props.para3 && <p> {props.para3} </p> }
                {props.para4 && <p> {props.para4} </p> }
                {props.para5 && <p> {props.para5} </p> }
                {props.para6 && <p> {props.para6} </p> }
               
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};

export default Location;
