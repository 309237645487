import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/privacypolicy.png";
import FooterComp from "../components/FooterComp";

const Privacy = () => {
  return (
    <>
      <Nav
        title="Privacy Policy: Boston Airport Taxi Service MA - Boston Airport Car Service"
        desc="Boston Airport Taxi Cab takes pride to best privacy policy for all rider"
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap contact__page">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h2 className=" text-center centered section__title hav__icon mb-5">
                Privacy Policy
              </h2>
              <p>
                Boston Airport Taxi Service MA takes pride to best privacy
                policy for all rider and we make every effort to preserve user
                privacy. If you feel that this company is disclosing user
                privacy, you should first contact Our Customer Support Manager
                at: contact@airporttaxima.com, Tel: (617) 405-3000, Fax: (866)
                558-8477
              </p>
              <h4 class="mb-2 mt-4  md__title ">
                Information Collection and use{" "}
              </h4>
              <p>
                In order to make online car service reservation from
                www.airporttaxima.com, a user have to provide some basic
                required information to process orders include: name, email
                address, pickup address, and phone number. This information is
                essential for customer’s orders to be processed and make online
                reservation. We do not ask users to give information such as:
                gender, etc. This information is used by Boston Airport Taxi
                Service MA to better serve and protects its valued customers.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Cookies </h4>
              <p>
                A cookie is a piece of data stored on the user’s hard drive
                containing information about the user. Usage of a cookie is in
                no way linked to any personally identifiable information while
                on our site. Once the user closes their browser, the cookie
                simply terminates. For instance, by setting a cookie on our
                site, the user would not have to log in with user account to
                book a car service thereby saving time while on our site. If a
                user rejects the cookie, they may still use our site. The only
                drawback to this is that the user will be limited in some areas
                of our site. For example, the user will not be able to
                participate in any of our Sweepstakes, Contests or monthly
                Drawings that take place. Cookies can also enable us to track
                and target the interests of our users to enhance the experience
                on our site.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Log Files</h4>
              <p>
                We use IP addresses to analyze trends, administer the site,
                track user’s movement, and gather broad demographic information
                for aggregate use. IP addresses are not linked to personally
                identifiable information. Please note that the access logs
                collected for www.airporttaxima.com contain more information
                than just IP addresses. They also let us know what operating
                system/Internet browser you use and what pages on our site you
                have viewed. As with your IP address, this information is not
                linked to personally identifiable information and is used in
                aggregate to ensure the functionality of the site for our
                visitors.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Links </h4>
              <p>
                This web site contains links to other sites. Please be aware
                that Boston Airport Taxi Service MA is not responsible for the
                privacy practices of such other sites. We encourage our users to
                be aware when they leave our site and to read the privacy
                statements of each and every web site that collects personally
                identifiable information. This privacy statement applies solely
                to information collected by this website.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Newsletter </h4>
              <p>
                If a user wishes to subscribe to our newsletter, we ask for
                contact information such as name and email address.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Surveys </h4>
              <p>
                From time-to-time our site requests information from users via
                surveys. Participation in these surveys is completely voluntary
                and the user therefore has a choice whether or not to disclose
                this information. Information requested may include contact
                information (such as name and Country Location), and demographic
                information (such as zip code, age level). Survey information
                will be used for purposes of monitoring or improving the use and
                satisfaction of this site.
              </p>
              <h4 class="mb-2 mt-4  md__title "> Referal-To-A-Friend </h4>
              <p>
                If a user elects to use our referral service for informing a
                friend about our site, we ask them for the friend’s name and
                email address. Boston Airport Taxi Service MA will automatically
                send the friend a one-time email referred them to visit the
                site. Boston Airport Taxi Service MA uses this information for
                the sole purpose of sending this one-time email and the
                information is not stored thereafter.
              </p>
              <h4 class="mb-2 mt-4  md__title ">
                Supplementation of Information{" "}
              </h4>
              <p>
                In order for this website to properly fulfill its obligation to
                our customers, it is necessary for us to supplement the
                information we receive with information from 3rd party sources.
                We use this information to ensure that the data we have is as
                up-to-date as possible.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Special Offers </h4>
              <p>
                We send all new regular user a welcoming email to verify
                password and username. Established client will occasionally
                receive information on services, special deals, and a
                newsletter. Out of respect for the privacy of our users we
                present the option to not receive these types of communications
                when registering.
              </p>
              <h4 class="mb-2 mt-4  md__title ">
                Correction/Updating Personal Information{" "}
              </h4>
              <p>
                If a user’s personally identifiable information changes (such as
                your address), or if a user no longer desires our service, we
                will endeavor to provide a way to correct, update or remove that
                user’s personal data provided to us. This can usually be done at
                the LOG-IN page or by emailing our Customer Support department
                (support@airporttaxima.com).
              </p>
              <h4 class="mb-2 mt-4  md__title ">Choice/Opt-out </h4>
              <p>
                Users who no longer wish to receive our newsletter may opt-out
                of receiving these communications by replying to unsubscribe in
                the subject line in the email or email us at
                privacy@airporttaxima.com.
              </p>
              <p>
                Users of our site are always notified when their information is
                being collected by any outside parties. We do this so our users
                can make an informed choice as to whether they should proceed
                with services that require an outside party, or not.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Notification of Changes </h4>
              <p>
                If we decide to change our privacy policy, we will post those
                changes on our Homepage so our users are always aware of what
                information we collect, how we use it, and under what
                circumstances, if any, we disclose it. If at any point we decide
                to use personally identifiable information in a manner different
                from that stated at the time it was collected, we would notify
                users by way of an email. Users will have a choice as to whether
                or not we use their information in this different manner. We
                will use information in accordance with the privacy policy under
                which the information was collected.
              </p>
              <h4 class="mb-2 mt-4  md__title ">Contact </h4>
              <p>
                If you have any questions about this privacy statement or your
                dealings with Boston Airport Taxi MA (www.airporttaxima.com),
                you can contact: info@airporttaxima.com
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};

export default Privacy;
