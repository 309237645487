import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/area.png";
import FooterComp from "../components/FooterComp";
import ListGroup from "react-bootstrap/ListGroup";

const AreaWeServe = ({ SeoTitle, SeoDesc }) => {
  return (
    <>
      <Nav SeoTitle="" SeoDesc="" />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap area__page">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h2 className=" text-center centered section__title hav__icon mb-5">
                Area We Serve
              </h2>
              <p>
                <strong>The Boston taxi service MA </strong> provides top class
                service to its clients.{" "}
                <strong> The airport taxi Boston</strong>
                service is available round the clock and 365 days in a year.
                Plus its huge fleet of car is very advantageous for the
                customers as they can choose from variety of cars like luxury
                sedans and SUVs.
                <strong> Boston taxi service </strong> is very economical and it
                affordable pricing makes it very popular among the customers.
                Plus its punctual service gives an added advantage. It has its
                regular service to Logan airport with its
                <strong>MA to Logan airport taxi </strong> service. The main USP
                of our service is the{" "}
                <strong> airport minivan service. </strong> This is taken by
                large groups and families travelling to airport and other places
                in Boston. They have customized seating arrangement and can
                transport group of 5/6 people. They are specially equipped with
                infant seating arrangement so that the families can travel with
                their infant with ease. The SUVs are preferred by the corporate
                houses so that can even have meeting while on their way to the
                airport or any other destination. The Boston taxi service has
                professional drivers who take care of your comfort as well as
                punctuality. We aim at being the best and hence there is no
                compromise on the service. We also have service in other areas
                as well like <strong> Waltham MA taxi service</strong> and{" "}
                <strong>Medford taxi service MA. </strong> The services of{" "}
                <strong>Lexington airport car service</strong> are very popular
                for airport transfers. You can easily book the cab online and
                the vehicle will be at your door step right at your designated
                time. We assure you of a comfortable ride with the best and well
                maintained car and our friendly and professional drivers who
                will indeed make your journey comfortable.
              </p>
              <h5  className="mt-5 " >
                <strong>
                  Airport Taxi Ma offer services in the following locations.
                </strong>
              </h5>
              <ListGroup className="four_col_list mt-4">
                <ListGroup.Item>
                  <a href="/taxi-cab-arlington">Arlington</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/taxi-cab-somerville">Somerville</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/taxi-cab-allston">Allston</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/taxi-cab-lexington">Lexington</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/taxi-cab-concord">Concord, MA</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/taxi-cab-medford">Medford</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/taxi-cab-winchester">Winchester</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/taxi-cab-belmont">Belmont</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/taxi-cab-cambridge">Cambridge</a>
                </ListGroup.Item>
              </ListGroup>
              <h5  className="mt-5">
                <strong>
                More Location We Serve
                </strong>
              </h5>
              <ListGroup className="three_col_list mt-4">
                <ListGroup.Item>
                  <a href="/belmont-ma-taxi-service">Belmont MA Taxi Service</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/airport-taxi-service-ma">Airport Taxi Service MA </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/boston-airport-taxi-service-ma">Boston Airport Taxi Service MA</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/ma-to-logan-airport-taxi-service">MA To Logan Airport Taxi Service</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/airport-taxi-boston">Airport Taxi Boston</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/boston-taxi-service">Boston Taxi Service</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/airport-taxi-cambridge-ma"> Airport Taxi Cambridge MA</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/airport-taxi-service-boston"> Airport Taxi Service Boston</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/taxi-service-boston-ma">Taxi Service Boston MA </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/concord-ma-taxi-services"> Concord MA Taxi Services </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/boston-taxi-services">Boston Taxi Services </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/winchester-ma-taxi-service">Winchester MA Taxi Service </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/taxi-service-medford-ma">Taxi Service Medford MA </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/boston-airport-minivan-service">Boston Airport Minivan Service </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/waltham-ma-taxi-service">Waltham MA Taxi Service </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href="/lexington-airport-car-service">Lexington Airport Car Service </a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </div>
      <FooterComp />
    </>
  );
};

export default AreaWeServe;
