import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/about-banner.png";
import FooterComp from "../components/FooterComp";

const Fleet = ({SeoTitle, SeoDesc}) => {
  return (
    <>
      <Nav
        SeoTitle="Boston Logan Airport Minivan Cab and Taxi Service at Airport Taxi Service MA"
        SeoDesc="Boston airport minivan service have been providing airport cab and taxi service for more years now and our specialized airport transfers have earned us much fame and honor. We offer affordable and reliable Logan airport taxi cab, minivan, town car and airport transportation services to Boston Logan Airport safely."
      />
      <InnerBanner BannerImage={Banner} />
      <section className="section__gap">
        <Container>
          <h1 className="text-center mb-5">Boston Airport Minivan Services</h1>
          <p>
            <strong> BOSTON AIRPORT MINIVAN SERVICE</strong> is the only taxis
            that have a service unique to its ability to convey you and your
            family, no matter the number of luggage you wish to convey and we
            are allowed to offer services out of the airport. Our minivans are
            not boring but exiting in looks and confortable to ensure your needs
            are met and your satisfaction which is our top priority is put in
            front of us and it is we ensure that you are well served and
            satisfied.
          </p>
          <p>
            We are known for our exemplary customer support, prompt service,
            qualified cars, and cost effective rates. We have been providing
            luxury pick and drop service for more years now and our specialized
            airport transfers have earned us much fame and honour on a national
            rating. All our cars are equipped with comfortable and cool
            interior. Our chauffeurs are well trained in meet and greet
            etiquette to receive your guests at airports with due honor and
            protocol. We also provide luggage assistance.
          </p>
        </Container>
      </section>
      <FooterComp />
    </>
  );
};

export default Fleet;
