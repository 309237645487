import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/faq-banner.png";
import FooterComp from "../components/FooterComp";

const Faq = ({ Data = null, SeoTitle, SeoDesc }) => {
  const defaultData = [
    {
      qus: "How do I book an Airport Taxi Service or Car Service for Airport Transfer?",
      ans: "Wee simple online form, from home page, just fill in the booking form, making sure you give us all the details necessary in flight number, arrival and departure time and mobile number.",
    },
    {
      qus: "Do I need a credit card for online Booking? ",
      ans: "Yes, we required a credit card information only for confirmation with holding $1.charge. But we do not use that credit card as a payment. We accept payment from passenger after the service and passenger is liable to pay the driver. ",
    },
    {
      qus: "How to pay with credit or debit card? ",
      ans: "We use credit card as our preferred payment solution for online Booking, we have most secure website payment gateway and you can book a car service without hesitation. ",
    },
    {
      qus: "Can I pay cash to driver? ",
      ans: "Yes, Credit card payment charged only $1.00 as a booking Confirmation. Remaining payment is due to driver after avail the service. You can pay by Cash or Credit Card to driver. ",
    },
    {
      qus: "Do I need to Pay driver If I paid by PayPal? ",
      ans: "No. Once you have booked ride by using PayPal Payment method, you do not have to pay anything to driver. ",
    },
    {
      qus: "What will happened if my flight get canceled? ",
      ans: "It’s very important that let us know at least 3 hours before scheduled pick up time to avoid any No-show. We recommended to re-schedule the service by email. ",
    },
    {
      qus: "How long will it take to get to the airport? ",
      ans: "Have a look at our estimated travel times. ",
    },
    {
      qus: "What if my driver does not turn up at the agreed time? ",
      ans: "Phone us, (617) 405-3000 – our call center is open 24 hours 365 days. ",
    },
    {
      qus: "I cannot locate my driver at the airport, where do I go? ",
      ans: "Our driver should be waiting for you with a name board near the airport information desk. ",
    },
    {
      qus: "What if I’m waiting for my luggage and there’s a delay? ",
      ans: "Contact us, the driver will wait for you as long as you let us know. Keep a note of our number: (617) 405-3000 or email us: info@airporttaxima.com ",
    },
     
  ];

  if (!Data) Data = defaultData;
  return (
    <>
      <Nav SeoTitle="FAQ: Boston Airport Taxi Service MA | Boston Airport Transportation Service" SeoDesc="Boston Airport Taxi Service MA simplified verity of cars booking option for Boston Logan International Airport Transfer by Logan airport taxi and Boston airport car Service" />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap faq__page">
        <Container >
          <h1 className="text-center mb-5">FAQs</h1>
          <Row>
            <Col lg="12">
              <div className="tabs">
                {Data.map((info, i) => (
                  <div className="tab" key={i}>
                    <input type="radio" id={i} name="rd" />
                    <label className="tab-label" for={i}>
                      {info.qus}
                    </label>
                    <div className="tab-content">{info.ans}</div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};

export default Faq;
