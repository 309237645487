import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/contact-banner.png";
import Form from "react-bootstrap/Form";
import FooterComp from "../components/FooterComp";
import Button from "react-bootstrap/Button";
import ReCAPTCHA from "react-google-recaptcha";

const Fleet = () => {
  return (
    <>
      <Nav
        SeoTitle="Contact us – Airport Taxi Service MA"
        SeoDesc="Airport Taxi Service MA - We are the most trusted & reliable Boston airport Minibus and Taxi cab service provider in Boston MA. We offer on-time arrival, security, affordability and a 100% satisfaction guarantee. Call us (617) 405-3000. For further information visit our website or email us info@airporttaxima.com."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap contact__page">
        <Container fluid>
          <Row>
            <Col lg="12">
              <h2 className=" text-center centered section__title hav__icon mb-5">
                Contact Us
              </h2>
            </Col>
            <Col lg="7">
              <h4 className="mb-4  md__title ">
                Contact us  
              </h4>

              <p>Boston - Cambridge, MA 02139</p>
              <p>
                <a href="tel:6174053000">(617) 405-3000</a>
              </p>
              <p>
                <a href="mailto:info@airporttaxima.com">
                info@airporttaxima.com
                </a>
              </p>
               <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.2705578583154!2d-71.05741948471243!3d42.35807294321992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e370869bdb88cf%3A0x5c46afebf8ba9fb1!2s6%20Liberty%20Sq%20%232294%2C%20Boston%2C%20MA%2002109%2C%20USA!5e0!3m2!1sen!2snp!4v1665741317138!5m2!1sen!2snp"
                width="100%"
                height="350"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>

            <Col lg="5">
              <Form className="contact-form">
                <h3>Send your Queries</h3>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Full Name" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Phone Number" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control type="text" placeholder="Email Address" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control as="textarea" rows="4" placeholder="Message" />
                </Form.Group>
                <ReCAPTCHA sitekey="111" />,
                <Button className="btn-block btn__main ">Submit</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <FooterComp />
    </>
  );
};

export default Fleet;
