import React from "react";
import Nav from "../components/NavComp";
import InnerBanner from "../components/InnerBanner";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "../images/fleet.png";
import FooterComp from "../components/FooterComp";
import Button from "react-bootstrap/Button";

import Img1 from "../images/suv.png";
import Img2 from "../images/sedan.png";
import Img3 from "../images/compact.png"; 
import Img4 from "../images/MINIVAN-BLACK.png"; 

const About = ({ Data = null }) => {
  const defaultData = [
    {
      title: "COMPACT SEDAN",
      description: "Compact Sedan for up-to 2-Passenger with 2 carry on Luggages | Economic & Spacious car.",
      img: Img1,
    },
    {
      title: "3-PAX SEDAN",
      description: "3-Pax Sedan for up-to 3-Passenger with Maximum 3/4 (THREE/FOUR) Luggage | Safe & Comfortable.",
      img: Img2,
    },
    {
      title: "6/7-PAX MINIVAN",
      description: "6/7 Passengers Minivan or up-to 6/7-Passengers with Maximum of 6 (SIX) Luggages | Best Car for family with child. Very Comfortable for Long Distance Ride. All type of child/infant seat available upon request.",
      img: Img3,
    },
    {
      title: "4-PAX-Minivan",
      description: "4-Pax-Minian for up-to 4-Passenger with larger amount of luggages | Maximum 10 (TEN) Luggages can fit in the Cargo-Van. | Best Car for family with child/infant with multiple Luggage. Very Comfortable Journey for Long Distance Rides. All type of child/infant seat available upon request.",
      img: Img4,
    },
 
  ];

  if (!Data) Data = defaultData;
  return (
    <>
      <Nav
        SeoTitle="Fleets | Affordable Car Rental and Taxi Cab Service"
        SeoDesc=" Enjoy fast and easy car rental bookings from Airport Taxi Service MA. We offer reliable Boston airport taxi service for any travelers with low price and there is no any hidden charges. We provide long distance car service to Boston to New York, Boston to Logan. Book now."
      />
      <InnerBanner BannerImage={Banner} />
      <div className="section__gap fleet-page">
        <Container fluid>
          <h2 className=" text-center section__title centered hav__icon mb-5">
          Our Fleets
          </h2>
          <p>
          With the immaculate wide range of vehicles, we can accommodate your transportation request. Whether its a small or group transfer from Hudson Airport or ..... to anywhere you can always rely on us.
          </p>
          <Row>
            <Col lg="12">
              <h4 className="mb-4   pb-5">
                <strong> Luxurious range of fleets</strong>
              </h4>
            </Col>
          </Row>
          <Row>
        {Data.map((info, i) => (
          <Col className="threecolwithimage__unit" xs lg="6" key={i}>
            <img src={info.img} style={{width:"300px ", marginBottom:"30px" , marginTop:"30px"}} alt={info.title || ""} />
            <h4>{info.title || ""}</h4>
            <p className="description">{info.description || ""}</p>
            <div className="price-box">
              <h5>{info.price}</h5>
            </div>
          </Col>
        ))}
      </Row>
        </Container>
      </div>
      
      <FooterComp />
    </>
  );
};
export default About;
